import { matrixOptions } from "@/constants/editor.js";
import { uuid } from '../utils/utils';

// 公共样式
export const commonStyle = {
  rotate: 0,
  opacity: 1
};

//按钮的公共属性
const btnStyles = {
  backgroundImage: "", //背景图片
  pressBackgroundImage: "",
  backgroundColor: "#409eff",
  pressBackgroundColor: "#ffff00",
  color: "#ffffff",
  border: "none",
  pressRing: false,
  btnPressName: "",
  btnLoading: false,
  btnLoadingTime: 5,
  kqds: false,
  alias: "",
  backgroundType: 'color',
  pressBackgroundType: 'color',
  backgroundInfo: {},
  pressBackgroundImageInfo: {},
};

export const componentIconConfig = {
  Picture: "tupian",
  "v-text": "wenzi",
  "v-button": "button",
  StreamMedia: "streamMedia",
  Video: "shipin",
  Document: "document",
  Webpage: "webpage",
  Weather: "duoyun",
  Slider: 'slider',
  LineComp: 'el-icon-minus',
  StatusLight: 'light'
};

const primaryBtnAttrs = {
  events: {
    mutuallyEvent: [],
    jumpEvent: [],
    pressEvent: [],
    pressAgainEvent: [],
  },
  networkConfig: {
    directives: [], //按压指令
    directives2: [], //二次按压指令
    ipAdress: "",
    port: "",
  },
};

const lineDefaultAttrs = {
  direction: "horizontal"
};
const weatherDefaultAttrs = {
  city: "",
  imgStyle: "",
  imgStyleName: ""
};

const dateDefaultAttrs = {
  fontSize: 14,
  color: "#000000",
};

const carouselDefaultAttrs = {
  carouselPics: [],
  interval: "",
  backgroundSize: "contain",
};

export const commonAttr = {
  animations: [],
  timeingEnable: "",
  groupStyle: {}, // 当一个组件成为 Group 的子组件时使用
  isLock: false, // 是否锁定组件
};

export const matrixComponents = matrixOptions.reduce((res, item) => {
  res[item.type] = generateBtn(item);
  return res;
}, {});
function generateBtn({ type, label, component, networkConfig }) {
  return {
    component,
    type,
    label,
    propValue: label,
    icon: "button",
    networkConfig,
    style: {
      width: 100,
      height: 34,
      fontSize: 14,
      fontWeight: 500,
      modalConfirm: false,
      ...btnStyles,
      ...commonStyle,
      alias: label
    },
  };
}

// 编辑器左侧组件列表
const list = [
  {
    component: "v-text",
    label: "文字",
    propValue: "双击编辑文字",
    icon: "wenzi",
    style: {
      width: 200,
      height: 44,
      fontSize: 30,
      fontFamily: '',
      fontWeight: 500,
      lineHeight: "",
      letterSpacing: 0,
      backgroundColor: "#ffffff",
      textAlign: "",
      color: "#000000",
      //文字滚动相关
      textScroll: false,
    },
  },
  {
    component: "LineComp",
    label: "线条",
    propValue: "",
    icon: "el-icon-minus",
    style: {
      width: 400,
      height: 1,
      backgroundColor: "#000000",
      ...lineDefaultAttrs,
    },
  },
  {
    component: "v-button",
    label: "方形按钮",
    type: "square",
    propValue: "方形按钮",
    icon: "button",
    ...primaryBtnAttrs,
    style: {
      width: 100,
      height: 34,
      borderRadius: 0,
      fontSize: 14,
      fontFamily: '',
      fontWeight: 500,
      oneClick: false,
      modalConfirm: false,
      ...btnStyles,
      alias: "方形按钮"
    },
  },
  {
    component: "v-button",
    type: "round",
    label: "圆形按钮",
    propValue: "圆形按钮",
    icon: "button",
    ...primaryBtnAttrs,
    style: {
      width: 80,
      height: 80,
      borderRadius: 40,
      fontSize: 14,
      fontFamily: '',
      fontWeight: 500,
      oneClick: false,
      modalConfirm: false,
      ...btnStyles,
      alias: "圆形按钮"
    },
  },
  {
    component: "v-button",
    type: "round-angle",
    label: "圆角按钮",
    propValue: "圆角按钮",
    icon: "button",
    ...primaryBtnAttrs,
    style: {
      width: 100,
      height: 34,
      borderRadius: 20,
      fontSize: 14,
      fontFamily: '',
      fontWeight: 500,
      oneClick: false,
      modalConfirm: false,
      ...btnStyles,
      alias: "圆角按钮"
    },
  },
  {
    component: "Picture",
    label: "图片",
    icon: "tupian",
    propValue: {
      file_down_path: require("@/assets/title.jpg"),
      file_real_name: "默认图片",
      file_md5: "default",
      id: "default",
      file_name: "默认图片",
    },
    style: {
      width: 300,
      height: 200,
      backgroundSize: "contain",
    },
  },
  {
    component: "StreamMedia",
    label: "流媒体",
    propValue: "",
    icon: "streamMedia",
    style: {
      width: 200,
      height: 100,
      fontSize: 64,
      color: "#000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      rtspAdressGroups: [
        {
          name: "",
          rtspAdress: "",
          id: new Date().valueOf(),
        },
      ],
    },
  },
  {
    component: "Video",
    label: "视频",
    propValue: "",
    icon: "shipin",
    style: {
      width: 200,
      height: 100,
      fontSize: 64,
      color: "#000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      videoNameGroups: [
        {
          name: "",
          id: new Date().valueOf(),
        },
      ],
    },
  },
  {
    component: "Weather",
    label: "天气",
    propValue: "",
    icon: "duoyun",
    style: {
      width: 450,
      height: 160,
      fontSize: 20,
      color: "#000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffff",
      ...weatherDefaultAttrs,
    },
  },
  {
    component: "Carousel",
    label: "多图轮播",
    icon: "lunbotu",
    style: {
      width: 200,
      height: 100,
      backgroundImage: "",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      ...carouselDefaultAttrs,
    },
  },
  {
    component: "Date",
    label: "时间",
    icon: "rili",
    style: {
      width: 200,
      height: 100,
      ...dateDefaultAttrs,
    },
  },
  {
    component: "Environment",
    label: "环境组件",
    icon: "rili",
    networkConfig: {
      ipAdress: "",
      port: "",
    },
    style: {
      width: 450,
      height: 200,
    },
  },
  {
    component: "Document",
    label: "文档",
    propValue: "",
    icon: "document",
    style: {
      width: 200,
      height: 100,
      fontSize: 64,
      color: "#000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      documentNameGroups: [
        {
          name: "",
          id: new Date().valueOf(),
        },
      ],
    },
  },
  {
    component: "Webpage",
    label: "网页",
    propValue: "",
    icon: "webpage",
    style: {
      width: 200,
      height: 100,
      fontSize: 64,
      color: "#000000",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      url: ''
    },
  },
  {
    component: "Slider",
    label: "滑块",
    propValue: "",
    icon: "slider",
    networkConfig: {
      ipAdress: "",
      port: "",
      // count: "",
      sliderList: [
        { id: new Date().valueOf(), unit: undefined, code: '' },
        { id: uuid(), unit: undefined, code: '' },
      ]
    },
    style: {
      width: 200,
      height: 100,
      direction: 'horizontal',
      circleColor: '#FFFFFF',
      circleSize: 6,
      trackColor: '#C0C4CC',
      activeColor: '#409EFF',
      trackSize: 6,
      fontColor: '#909399',
      fontSize: 14
    },
  },
  {
    component: "StatusLight",
    label: "状态灯",
    icon: "light",
    activeDetectionConfig: {
      logStart: '0',
      deviceName: '',
      ipAdress: '',
      port: '',
      // bindDirectives: [],
      // time: '3',
      // validateCorrect: 'color',
      // validateFail: 'color',
      // validateCorrectColor: '#67c23a',
      // validateCorrectIcon: '',
      // validateFailColor: '#ff0000',
      // validateFailIcon: '',
      pingStatus: '1',
      pingTime: '3',
      pingCorrect: 'color',
      pingFail: 'color',
      pingCorrectColor: '',
      pingCorrectIcon: '',
      pingFailColor: '',
      pingFailIcon: ''
    },
    passiveMonitorConfig: {
      logStart: '0',
      devices: [
        {
          id: 1,
          deviceName: '',
          ipAdress: '',
          port: '',
          bindDirectives: [],
          time: '3',
          deviceCalls: [
            { id: 1, callbackInfo: '', callbackUnicode: '1', callbackStatus: 'color', callbackStatusColor: '#67c23a', callbackStatusIcon: '', enterSpaceName: '0' }
          ]
        }
      ]
    },
    style: {
      width: 32,
      height: 32,
      lightBackground: 'color',
      lightBackgroundColor: '#409eff',
      lightBackgroundImage: '',
      lightBackgroundImageInfo: {}
    }
  }
];

for (let i = 0, len = list.length; i < len; i++) {
  const item = list[i];
  item.style = { ...commonStyle, ...item.style };
  list[i] = { ...commonAttr, ...item };
}

export default list;
